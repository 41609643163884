import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import '../App.css';
// import AxiosInstance from './AxiosInstance';

const Home = () => {
  const { firstname, lastname } = useSelector((state) => state.user);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!firstname || !lastname) {
      navigate('/login');
    }
  }, [firstname, lastname, navigate]);

  return (
    <div>
      {/* Simple Navbar */}
      <nav className="navbar">
        <div className="navbar-content">
          <h2 className="welcome-message">Welcome, {firstname} {lastname}!</h2>
        </div>
      </nav>

      {/* Rest of your page content */}
      <div className="home-content">
        {/* Additional content for the home page */}
      </div>
    </div>
  );
};

export default Home;
