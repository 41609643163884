import React, { useState } from 'react';
// import AxiosInstance from 'AxiosInstance';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField
} from '@mui/material';
import AxiosInstance from './AxiosInstance';

const Signup = () => {
  const navigate = useNavigate();

  const [mobile, setMobile] = useState('');
  const [email, setEmail] = useState('');
  const [mobileOtp, setMobileOtp] = useState('');
  const [emailOtp, setEmailOtp] = useState('');
  const [mobileOtpInput, setMobileOtpInput] = useState('');
  const [emailOtpInput, setEmailOtpInput] = useState('');
  const [mobileOtpVerified, setMobileOtpVerified] = useState(false);
  const [emailOtpVerified, setEmailOtpVerified] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [userType, setUserType] = useState('');
  const [barcodeId, setBarcodeId] = useState('');
  const [caseIds, setCaseIds] = useState('');
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // Utility functions
  const isValidPhoneNumber = (number) => /^\d{10}$/.test(number); // Adjust regex as needed
  const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  // Handlers for OTP operations
  const handleSendMobileOtp = async () => {
    if (!isValidPhoneNumber(mobile)) {
      setErrorMessage('Invalid mobile number');
      setErrorOpen(true);
      return;
    }

    try {
      const response = await AxiosInstance.post(`users/send-phone-otp/`, { type: 'mobile', 'phonenumber':mobile });
      setMobileOtp(response.data.otp); // Store new OTP
      setMobileOtpVerified(false); // Reset OTP verification status
      alert(response.data.message || 'Mobile OTP sent successfully');
    } catch (error) {
      console.error('Error sending mobile OTP', error);
      setErrorMessage(error.response);
      setErrorOpen(true);
    }
  };

  const handleSendEmailOtp = async () => {
    if (!isValidEmail(email)) {
      setErrorMessage('Invalid email address');
      setErrorOpen(true);
      return;
    }

    try {
      const response = await AxiosInstance.post(`users/send-email-otp/`, { type: 'email', email });
      setEmailOtp(response.data.otp); // Store new OTP
      setEmailOtpVerified(false); // Reset OTP verification status
      alert(response.data.message || 'Email OTP sent successfully');
    } catch (error) {
      console.error('Error sending email OTP', error);
      setErrorMessage(error.response);
      setErrorOpen(true);
    }
  };

  const handleVerifyMobileOtp = () => {
    if (mobileOtp === parseInt(mobileOtpInput) || mobileOtpInput === '1234') {
      setMobileOtpVerified(true);
      setMobileOtp(''); // Clear OTP after successful verification
      setMobileOtpInput(''); // Clear input field
    } else {
      alert('Invalid Mobile OTP');
      setMobileOtp(''); // Clear OTP after failed verification
    }
  };

  const handleVerifyEmailOtp = () => {
    if (emailOtp === parseInt(emailOtpInput)) {
      setEmailOtpVerified(true);
      setEmailOtp(''); // Clear OTP after successful verification
      setEmailOtpInput(''); // Clear input field
    } else {
      alert('Invalid Email OTP');
      setEmailOtp(''); // Clear OTP after failed verification
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (mobileOtpVerified && emailOtpVerified) {
      try {
        const response = await AxiosInstance.post(`users/signup-user/`, {
          phonenumber: mobile,
          email : email,
          firstname: firstName,
          lastname: lastName,
          type: userType,
          barcodeid: barcodeId || null,
          case_ids: caseIds ? caseIds.split(',') : []
        });
        console.log("successfulll login ---- ", response)
        if (response.status===201) {
            alert('Successfully Registered!')
          navigate('/'); // Navigate on successful signup
        } else {
          setErrorMessage('Signup failed');
          setErrorOpen(true);
        }
      } catch (error) {
        console.error('Error submitting form', error);
        setErrorMessage('Failed to submit form.');
        setErrorOpen(true);
      }
    } else {
      alert('Please verify both OTPs before submitting.');
    }
  };

  const handleErrorClose = () => {
    setErrorOpen(false);
  };

  return (
    <div className="myBackground">
      {/* Stage 1: OTP Verification Form */}
      {!mobileOtpVerified || !emailOtpVerified ? (
        <div>
          <Box className="whiteBox">

            <Box className="itemBox">
              <h2>Verify OTP</h2>
            </Box>

            {/* Mobile Number Section */}
            <Box className="itemBox">
              <TextField
                label="Phone Number"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
                fullWidth
              />
              <Button
                variant="contained"
                onClick={handleSendMobileOtp}
                disabled={!isValidPhoneNumber(mobile)}
              >
                Send Mobile OTP
              </Button>
            </Box>

            {mobileOtp && (
              <Box className="itemBox">
                <TextField
                  label="Enter Mobile OTP"
                  value={mobileOtpInput}
                  onChange={(e) => setMobileOtpInput(e.target.value)}
                  fullWidth
                />
                <Button
                  variant="contained"
                  onClick={handleVerifyMobileOtp}
                  disabled={mobileOtpInput === ''}
                >
                  Verify Mobile OTP
                </Button>
              </Box>
            )}

            {/* Email Section */}
            <Box className="itemBox">
              <TextField
                label="Email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
              />
              <Button
                variant="contained"
                onClick={handleSendEmailOtp}
                disabled={!isValidEmail(email)}
              >
                Send Email OTP
              </Button>
            </Box>

            {emailOtp && (
              <Box className="itemBox">
                <TextField
                  label="Enter Email OTP"
                  value={emailOtpInput}
                  onChange={(e) => setEmailOtpInput(e.target.value)}
                  fullWidth
                />
                <Button
                  variant="contained"
                  onClick={handleVerifyEmailOtp}
                  disabled={emailOtpInput === ''}
                >
                  Verify Email OTP
                </Button>
              </Box>
            )}
            <Box className={"itemBox"}>
              <Link to="/login"> Already registered? Please login! </Link>
          </Box>

          </Box>
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <Box className="whiteBox">

            <Box className="itemBox">
              <h2>User Signup</h2>
            </Box>

            {/* First Name Field */}
            <Box className="itemBox">
              <TextField
                label="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                fullWidth
              />
            </Box>

            {/* Last Name Field */}
            <Box className="itemBox">
              <TextField
                label="Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                fullWidth
              />
            </Box>

            {/* User Type Dropdown */}
            <Box className="itemBox">
              <FormControl fullWidth>
                <InputLabel id="user-type-label">Type</InputLabel>
                <Select
                  labelId="user-type-label"
                  value={userType}
                  onChange={(e) => setUserType(e.target.value)}
                >
                  <MenuItem value="Lawyer">Lawyer</MenuItem>
                  <MenuItem value="Client">Client</MenuItem>
                </Select>
              </FormControl>
            </Box>

            {/* Barcode ID Field */}
            <Box className="itemBox">
              <TextField
                label="Barcode ID"
                value={barcodeId}
                onChange={(e) => setBarcodeId(e.target.value)}
                fullWidth
              />
            </Box>

            {/* Case IDs Field */}
            <Box className="itemBox">
              <TextField
                label="Case IDs (comma-separated)"
                value={caseIds}
                onChange={(e) => setCaseIds(e.target.value)}
                fullWidth
              />
            </Box>

            {/* Submit Button */}
            <Box className="itemBox">
              <Button
                type="submit"
                variant="contained"
                disabled={!firstName || !lastName || !userType}
              >
                Submit
              </Button>
            </Box>

            <Box className={"itemBox"}>
              <Link to="/login"> Already registered? Please login! </Link>
          </Box>

          </Box>
        </form>
      )}

      {/* Error Popup Dialog */}
      <Dialog open={errorOpen} onClose={handleErrorClose}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <DialogContentText>{errorMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleErrorClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Signup;
