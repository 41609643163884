// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.myBackground{
  width:100vw;
  height: 100vh;
  display: flex;
  align-items: center;

  justify-content: center;
  background: rgb(205,0,118);
  background: linear-gradient(137deg, rgba(205,0,118,1) 0%, rgba(85,35,185,1) 68%); 
}

.whiteBox{
  background-color: white;
  padding: 20px; 
  min-width: 300px;
  height: 70%;
  width: 25%;
  border: 1px solid #333;
}

.itemBox{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
}

.title{
  font-size: 30px;
  color: #333;
}

.myForm, 
.myButton{
  width: 100%;
}

.myButton{
  background: rgb(66, 8, 160) !important;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,aAAa;EACb,mBAAmB;;EAEnB,uBAAuB;EACvB,0BAA0B;EAC1B,gFAAgF;AAClF;;AAEA;EACE,uBAAuB;EACvB,aAAa;EACb,gBAAgB;EAChB,WAAW;EACX,UAAU;EACV,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,eAAe;EACf,WAAW;AACb;;AAEA;;EAEE,WAAW;AACb;;AAEA;EACE,sCAAsC;AACxC","sourcesContent":[".myBackground{\n  width:100vw;\n  height: 100vh;\n  display: flex;\n  align-items: center;\n\n  justify-content: center;\n  background: rgb(205,0,118);\n  background: linear-gradient(137deg, rgba(205,0,118,1) 0%, rgba(85,35,185,1) 68%); \n}\n\n.whiteBox{\n  background-color: white;\n  padding: 20px; \n  min-width: 300px;\n  height: 70%;\n  width: 25%;\n  border: 1px solid #333;\n}\n\n.itemBox{\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  height: 80px;\n}\n\n.title{\n  font-size: 30px;\n  color: #333;\n}\n\n.myForm, \n.myButton{\n  width: 100%;\n}\n\n.myButton{\n  background: rgb(66, 8, 160) !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
