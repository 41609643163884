// src/App.js
import './App.css';
import Home from './components/Home';
import Signup from './components/Signup';
import Login from './components/Login';
import About from './components/About';
import Navbar from './components/Navbar';
import { Routes, Route, useLocation } from 'react-router-dom';
import ProtectedRoute from './components/ProtectedRoutes';
import { BrowserRouter as Router, useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUser, clearUser } from './features/userSlice';
import CalendarComponent from './components/CalendarComponent';
import CreateDrafts from './components/CreateDrafts';
import SendEmailComponent from './components/SendEmailComponent';

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

function AppContent() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { firstname, lastname } = useSelector((state) => state.user);
  const noNavbar = location.pathname === "/signup" || location.pathname === "/login" || location.pathname === "/";

  useEffect(() => {
  }, );

  // const handleLogout = () => {
  //   localStorage.removeItem('firstname');
  //   localStorage.removeItem('lastName');
  //   dispatch(clearUser());
  //   navigate('/login');
  // };

  return (
    <>
      {noNavbar ? (
        <Routes>
          <Route path="" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/" element={<Login />} />
        </Routes>
      ) : (
        <Navbar
          content={
            <Routes>
              <Route element={<ProtectedRoute />}>
                <Route path="/home" element={<Home firstName={firstname} lastName={lastname} />} />
                <Route path="/about" element={<About />} />
                <Route path="/calendar" element={<CalendarComponent />} />
                <Route path="/create-drafts" element={<CreateDrafts />} />
                <Route path="/send-email" element={<SendEmailComponent />} />
              </Route>
            </Routes>
          }
        />
      )}
    </>
  );
}

export default App;
