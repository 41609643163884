import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, FormControl, InputLabel, Select, MenuItem, Grid2, TextField, Button, Typography, CircularProgress, Backdrop, InputAdornment  } from '@mui/material';
import AxiosInstance from './AxiosInstance';
import DatePicker from 'react-datepicker';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import 'react-datepicker/dist/react-datepicker.css';

const CreateDrafts = () => {
    const { email } = useSelector((state) => state.user);
  const [draftTypes, setDraftTypes] = useState([]);
  const [draftItems, setDraftItems] = useState([]);
  const [selectedDraftType, setSelectedDraftType] = useState('');
  const [selectedDraftItem, setSelectedDraftItem] = useState('');
  const [formFields, setFormFields] = useState({});
  const [formData, setFormData] = useState({});
  const [pdfBlob, setPdfBlob] = useState(null);
  const [loading, setLoading] = useState(false); // Loading state
    const [selectedDate, setSelectedDate] = useState(null);
    
  // Fetch draft types on component mount
  useEffect(() => {
    const fetchDraftTypes = async () => {
      setLoading(true); // Start loading
      try {
        const response = await AxiosInstance.get(`drafts/get-all-drafts/`); // Replace with your API URL
        setDraftTypes(response.data.dir_list); // Ensure correct assignment
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false); // Stop loading
      }
    };

    fetchDraftTypes();
  }, []);

  useEffect(() => {
    const fetchDraftItems = async () => {
      if (selectedDraftType) {
        setLoading(true); // Start loading
        try {
          const response = await AxiosInstance.get(`drafts/draft-items?type=${selectedDraftType}`); // Replace with your API URL
          setDraftItems(response.data.all_drafts_list);
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false); // Stop loading
        }
      }
    };

    fetchDraftItems();
  }, [selectedDraftType]);

  const handleDraftItemSelection = async (draftItem) => {
    setLoading(true); // Start loading
    try {
      const response = await AxiosInstance.get(`drafts/draft-fields?type=${selectedDraftType}&item=${draftItem}`);
      let fields = response.data.req_fields;

      // Parse fields if they come as a string
      if (typeof fields === 'string') {
        try {
          fields = JSON.parse(fields); // Parse JSON string to object
        } catch (error) {
          console.error('Error parsing req_fields JSON:', error);
          return; // Early exit if parsing fails
        }
      }

      setFormFields(fields);
      setFormData(initializeFormData(fields));
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const initializeFormData = (fields) => {
    const data = {};
    const setInitialValues = (obj, prefix = '') => {
      Object.entries(obj).forEach(([key, value]) => {
        if (typeof value === 'object' && !Array.isArray(value)) {
          setInitialValues(value, `${prefix}${key}.`);
        } else {
          data[`${prefix}${key}`] = value;
        }
      });
    };
    setInitialValues(fields);
    return data;
  };

  const handleInputChange = (e, key) => {
    setFormData({ ...formData, [key]: e.target.value });
  };

  const handleSubmit = async () => {
    const payload = {
      type: selectedDraftType,
      item: selectedDraftItem,
      email_id: email,
      fields: formData,
    };

    setLoading(true); // Start loading
    try {
      const response = await AxiosInstance.post(`drafts/submit-draft/`, payload, { responseType: 'blob' }); // Expecting a PDF file
      const blob = new Blob([response.data], { type: 'application/pdf' });
      setPdfBlob(blob);  // Store the PDF blob in state
      console.log('Draft submitted successfully:', response);
    } catch (error) {
      console.error('Error submitting draft:', error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const downloadPdf = () => {
    if (!pdfBlob) return;

    const url = window.URL.createObjectURL(pdfBlob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', selectedDraftItem.split('.')[0] + '.pdf'); // Set the download filename
    document.body.appendChild(link);
    link.click();
    link.remove();  // Clean up the DOM
    window.URL.revokeObjectURL(url);  // Release memory
  };

      const renderFormFields = (fields, prefix = '') => {
  return Object.entries(fields).map(([key, value]) => {
    const isRequired = value.required === 'True'; 
    const dataType = value.datatype;
    const description = value.desc;
      
    let fieldComponent;

     if (dataType === 'datetime') {
      fieldComponent = (
        <DatePicker
          key={prefix + key}
          selected={formData[`${prefix}${key}`] || null} // Use formData for the selected date
          onChange={(date) => {
            handleInputChange({ target: { value: date } }, `${prefix}${key}`); // Update formData with the new date
          }}
          customInput={
            <TextField
              label={`${key.replace(/_/g, ' ')} (${description})`}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CalendarTodayIcon />
                  </InputAdornment>
                ),
              }}
              required={isRequired}
            />
          }
          dateFormat="P" // Show date only
        />
      );
    } else if (dataType === 'str') {
      fieldComponent = (
        <TextField
          key={prefix + key}
          label={`${key.replace(/_/g, ' ')} (${description})`}
          variant="outlined"
          fullWidth
          sx={{ marginBottom: 2 }}
          value={formData[`${prefix}${key}`] || ''}
          onChange={(e) => handleInputChange(e, `${prefix}${key}`)}
          required={isRequired}
          disabled={loading}
        />
      );
    } else if (dataType === 'int') {
      fieldComponent = (
        <TextField
          key={prefix + key}
          label={`${key.replace(/_/g, ' ')} (${description})`}
          variant="outlined"
          fullWidth
          sx={{ marginBottom: 2 }}
          type="number"
          value={formData[`${prefix}${key}`] || ''}
          onChange={(e) => handleInputChange(e, `${prefix}${key}`)}
          required={isRequired}
          disabled={loading}
        />
      );
    } else {
      return null;
    }

    return <Box key={prefix + key} sx={{ marginBottom: 2 }}>{fieldComponent}</Box>;
  });
};

  return (
    <Box sx={{ padding: 2 }}>
      {/* Loading backdrop */}
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <FormControl fullWidth sx={{ marginBottom: 2 }} disabled={loading}>
        <br></br>
        <InputLabel id="select-draft-type-label">Select Draft Type</InputLabel>
        <Select
          labelId="select-draft-type-label"
          value={selectedDraftType}
          onChange={(e) => setSelectedDraftType(e.target.value)}
          displayEmpty
          sx={{ color: 'black', minWidth: 120 }}
          disabled={loading}
        >
          <MenuItem value="" disabled>
            <em>Select Draft Type</em>
          </MenuItem>
          {draftTypes.map((type, index) => (
            <MenuItem key={index} value={type}>
              {type}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {selectedDraftType && (
        <FormControl fullWidth sx={{ marginBottom: 2 }} disabled={loading}>
          <InputLabel id="select-draft-item-label">Select Draft Item</InputLabel>
          <br></br>
          <Select
            labelId="select-draft-item-label"
            value={selectedDraftItem}
            onChange={(e) => {
              setSelectedDraftItem(e.target.value);
              handleDraftItemSelection(e.target.value);
            }}
            displayEmpty
            sx={{ color: 'black', minWidth: 120 }}
            disabled={loading}
          >
            <MenuItem value="" disabled>
              <em>Select Draft Item</em>
            </MenuItem>
            {draftItems.map((item, index) => (
              <MenuItem key={index} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      {Object.keys(formFields).length > 0 && (
        <Box>
          {renderFormFields(formFields)}
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={loading}
          >
            Submit
          </Button>

          {pdfBlob && (
            <Button variant="contained" color="secondary" onClick={downloadPdf} sx={{ marginLeft: 2 }}>
              Download PDF
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
};

export default CreateDrafts;
