import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useSelector, useDispatch } from 'react-redux';
import { clearUser } from '../features/userSlice';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import EventIcon from '@mui/icons-material/Event';
import LogoutIcon from '@mui/icons-material/Logout';
import EditNoteSharpIcon from '@mui/icons-material/EditNoteSharp';
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import { Link, useLocation } from 'react-router-dom';

const drawerWidth = 240;

const Navbar = ({ content }) => {
  const location = useLocation();
  const dispatch = useDispatch();

    const { firstname, lastname } = useSelector((state) => state.user);
    const path = location.pathname;

  const onLogout = () => {
    dispatch(clearUser());
    // Optionally navigate to login page
  };
      
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <Typography variant="h6" noWrap component="div">
            {`Welcome, ${firstname} ${lastname}`}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
          <List>
            <ListItem key={1} disablePadding>
              <ListItemButton component={Link} to="/home" selected={"/home" === path}>
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primary={"Home"} />
              </ListItemButton>
            </ListItem>

            <ListItem key={2} disablePadding>
              <ListItemButton component={Link} to="/about" selected={"/about" === path}>
                <ListItemIcon>
                  <InfoIcon />
                </ListItemIcon>
                <ListItemText primary={"About"} />
              </ListItemButton>
            </ListItem>

            <ListItem key={3} disablePadding>
                <ListItemButton component={Link} to="/calendar" selected={"/calendar" === path}>
                  <ListItemIcon>
                    <EventIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Calendar"} />
                </ListItemButton>
              </ListItem>
            
              <ListItem key={4} disablePadding>
                <ListItemButton component={Link} to="/create-drafts" selected={"/create-drafts" === path}>
                    <ListItemIcon>
                    <EditNoteSharpIcon /> {/* You can use an appropriate icon */}
                    </ListItemIcon>
                    <ListItemText primary={"Create Drafts"} />
                </ListItemButton>
            </ListItem>

            <ListItem key={5} disablePadding>
                <ListItemButton component={Link} to="/send-email" selected={"/send-email" === path}>
                    <ListItemIcon>
                    <AlternateEmailOutlinedIcon /> {/* You can use an appropriate icon */}
                    </ListItemIcon>
                    <ListItemText primary={"Send Email"} />
                </ListItemButton>
            </ListItem>

            <ListItem key={6} disablePadding>
              <ListItemButton onClick={onLogout}>
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary={"Logout"} />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        {content}
      </Box>
    </Box>
  );
}
    export default Navbar;
