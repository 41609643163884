import React, { useState, useEffect } from 'react';
import {
  Box, Button, TextField, FormControl, Grid2, InputLabel, Select, MenuItem, 
  Dialog, DialogActions, DialogContent, Typography, DialogTitle, Snackbar 
} from '@mui/material';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { useSelector } from 'react-redux';
import AxiosInstance from './AxiosInstance';

const Calendar = () => {
  const { firstname, lastname, email } = useSelector((state) => state.user);
  const [events, setEvents] = useState([]);
  const [open, setOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [eventData, setEventData] = useState({
    id: null, // Added for event identification during update
    title: '',
    startDate: '',
    endDate: '',
    startTime: '',
    endTime: '',
    partyBEmail: '',
    meetingtype: '',
    caseId: '',
    courtName: '', // Added field
    courtNumber: '', // Added field
    judgeName: '', // Added field
    taskType: 'Personal',
    eventType: '', // Added field for event type
    sendReminder: 'None',
  });
  const [months, setMonths] = useState([]);
  const [years, setYears] = useState([]);
  const [filters, setFilters] = useState({
    taskType: 'All',
    meetingtype: 'All',
    eventType: 'All'
  });

  const [filteredEvents, setFilteredEvents] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [isEditing, setIsEditing] = useState(false); // Track if editing

  // Initialize months and years for dropdowns
  useEffect(() => {
    setMonths([...Array(12).keys()].map(i => new Date(0, i).toLocaleString('default', { month: 'long' })));
    setYears(Array.from({ length: 10 }, (_, i) => new Date().getFullYear() + i));
  }, []);

  // Fetch existing events from backend
  useEffect(() => {
    fetchEvents();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [filters, events]);

  const applyFilters = () => {
    const newFilteredEvents = events.filter(event => {
      return (
        (filters.taskType === 'All' || event.extendedProps.taskType === filters.taskType) &&
        (filters.meetingtype === 'All' || event.extendedProps.meetingType === filters.meetingtype) &&
        (filters.eventType === 'All' || event.extendedProps.eventType === filters.eventType)
      );
    });
    setFilteredEvents(newFilteredEvents);
  };

  // Function to handle filter change
  const handleFilterChange = (filterName, value) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [filterName]: value
    }));
  };

  // Function to clear filters
  const clearFilters = () => {
    setFilters({
      taskType: 'All',
      meetingtype: 'All',
      eventType: 'All'
    });
  };

  const fetchEvents = () => {
    const email_id = email;
    AxiosInstance.get(`calendar/get-all-events?email_id=${email_id}`)
      .then(response => {
        const fetchedEvents = response.data.events.meetings.map(meeting => {
          const key = Object.keys(meeting)[0];
          const event = meeting[key];
          const { title, start, end, ...rest } = event;
          return {
            id: key,
            title: title,
            start: start,
            end: end,
            extendedProps: {
              ...rest
            }
          };
        });
        setEvents(fetchedEvents);
      })
      .catch(error => console.error('Error fetching events:', error));
  };

  const handleDateSelect = (selectInfo) => {
    setEventData({
      key: null,
      title: '',
      startDate: selectInfo.startStr ? selectInfo.startStr.split('T')[0] : '',
      endDate: selectInfo.endStr ? selectInfo.endStr.split('T')[0] : '',
      startTime: selectInfo.startStr ? (selectInfo.startStr.split('T')[1] || '09:00') : '09:00',
      endTime: selectInfo.endStr ? (selectInfo.endStr.split('T')[1] || '10:00') : '10:00',
      partyBEmail: '',
      meetingtype: '',
      caseId: '',
      courtName: '',
      courtNumber: '',
      judgeName: '',
      taskType: 'Personal',
      eventType: '',
      sendReminder: 'None',
    });
    setIsEditing(false);
    setOpen(true);
  };

  const handleEventClick = (clickInfo) => {
    const event = clickInfo.event;
    setEventData({
      key: event.id,
      title: event.title,
      startDate: event.startStr.split('T')[0],
      endDate: event.endStr ? event.endStr.split('T')[0] : '',
      startTime: event.startStr.split('T')[1] || '09:00',
      endTime: event.endStr ? event.endStr.split('T')[1] : '10:00',
      partyBEmail: event.extendedProps.partyBEmail || '',
      meetingtype: event.extendedProps.meetingtype || '',
      caseId: event.extendedProps.caseId || '',
      courtName: event.extendedProps.courtName || '',
      courtNumber: event.extendedProps.courtNumber || '',
      judgeName: event.extendedProps.judgeName || '',
      taskType: event.extendedProps.taskType || 'Personal',
      eventType: event.extendedProps.eventType || '',
      sendReminder: event.extendedProps.sendReminder || 'None',
    });
    setIsEditing(true);
    setOpen(true);
  };

  const handleEventSubmit = async () => {
    try {
      if (!eventData.title || !eventData.startDate || !eventData.endDate) {
        throw new Error('Title, Start Date, and End Date are required.');
      }

      const payload = {
        key: eventData.key, 
        title: eventData.title,
        start: `${eventData.startDate}T${eventData.startTime}`,
        end: `${eventData.endDate}T${eventData.endTime}`,
        eventType: eventData.eventType,
        partyBEmail: eventData.partyBEmail,
        meetingtype: eventData.meetingtype,
        caseId: eventData.caseId,
        Status: 'Y',
        courtName: eventData.courtName,
        courtNumber: eventData.courtNumber,
        judgeName: eventData.judgeName,
        taskType: eventData.taskType,
        sendReminder: eventData.sendReminder,
        email_id: email,
        fname: firstname,
        lname: lastname,
      };

      let response;
      if (isEditing && eventData.key) {
        response = await AxiosInstance.put(`calendar/update-event/${eventData.key}`, payload);
      } else {
        response = await AxiosInstance.post(`calendar/add-event/`, payload);
      }

      const returnedEvent = response.data.event || response.data;
      const newEvent = {
        id: returnedEvent.id || payload.key,
        title: returnedEvent.title || payload.title,
        start: returnedEvent.start || payload.start,
        end: returnedEvent.end || payload.end,
        extendedProps: {
          ...returnedEvent,
        }
      };

      if (isEditing) {
        setEvents(events.map(event => (event.id === newEvent.id ? newEvent : event)));
      } else {
        setEvents([...events, newEvent]);
      }

      setOpen(false);
      setIsEditing(false);
      setEventData({
        key: null,
        title: '',
        startDate: '',
        endDate: '',
        startTime: '',
        endTime: '',
        partyBEmail: '',
        meetingtype: '',
        caseId: '',
        courtName: '',
        courtNumber: '',
        judgeName: '',
        taskType: 'Personal',
        eventType: '',
        sendReminder: 'None'
      });
      setSuccessMessage(true);
    } catch (error) {
      let message = 'Error submitting event:';
      if (error.response && error.response.data && error.response.data.response) {
        message += ` ${error.response.data.response}`;
      } else {
        message += ' Please try again.';
      }
      setErrorMessage(message);
      console.error('Error submitting event:', error);
    }
  };

  const handleDeleteEvent = async () => {
    try {
      if (!eventData.key) {
        throw new Error('Event key is missing.');
      }
      await AxiosInstance.delete(`calendar/delete-event/${eventData.key}`);
      setEvents(events.filter(event => event.id !== eventData.key));
      setOpen(false);
      setIsEditing(false);
      setSuccessMessage(true);
    } catch (error) {
      console.error('Error deleting event:', error);
      setErrorMessage('Error deleting event.');
    }
  };

  const handleEventTypeChange = (e) => {
    const eventType = e.target.value;
    setEventData(prev => ({ ...prev, eventType }));

    // Reset specific fields based on the selected event type
    if (eventType === 'Case date') {
      setEventData(prev => ({ ...prev, courtName: '', courtNumber: '', caseId: '', judgeName: '' }));
    } else if (eventType === 'Client appointment') {
      setEventData(prev => ({ ...prev, caseId: '', judgeName: '', courtName: '', courtNumber: '' }));
    } else if (eventType === 'Court officer appointment') {
      setEventData(prev => ({ ...prev, courtName: '', caseId: '', courtNumber: '', judgeName: '' }));
    }
  };

  return (
    <Box sx={{ padding: 2 }}>
      {/* Event Creation Button */}
      <Box sx={{ marginBottom: 2, display: 'flex', justifyContent: 'space-between' }}>
        <Button variant="contained" color="primary" onClick={() => {
          setEventData({
            id: null,
            title: '',
            startDate: '',
            endDate: '',
            startTime: '',
            endTime: '',
            partyBEmail: '',
            meetingtype: '',
            caseId: '',
            courtName: '',
            courtNumber: '',
            judgeName: '',
            taskType: 'Personal',
            eventType: '',
            sendReminder: 'None',
          });
          setIsEditing(false);
          setOpen(true);
        }}>
          Create Event
        </Button>

        {/* Filter Component */}
        <Box sx={{ display: 'flex', gap: 2 }}>
          <FormControl sx={{ minWidth: 120 }}>
            <InputLabel>Task Type</InputLabel>
            <Select
              value={filters.taskType}
              onChange={(e) => handleFilterChange('taskType', e.target.value)}
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="Personal">Personal</MenuItem>
              <MenuItem value="Client Meeting">Client Meeting</MenuItem>
            </Select>
          </FormControl>

          <FormControl sx={{ minWidth: 120 }}>
            <InputLabel>Meeting Type</InputLabel>
            <Select
              value={filters.meetingtype}
              onChange={(e) => handleFilterChange('meetingtype', e.target.value)}
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="InPerson">In Person</MenuItem>
              <MenuItem value="VideoCall">Video Call</MenuItem>
              <MenuItem value="VoiceCall">Voice Call</MenuItem>
            </Select>
          </FormControl>

          <FormControl sx={{ minWidth: 120 }}>
            <InputLabel>Event Type</InputLabel>
            <Select
              value={filters.eventType}
              onChange={(e) => handleFilterChange('eventType', e.target.value)}
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="Case date">Case Date</MenuItem>
              <MenuItem value="Client appointment">Client Appointment</MenuItem>
              <MenuItem value="Court officer appointment">Court Officer Appointment</MenuItem>
            </Select>
          </FormControl>
          <Button variant="outlined" onClick={clearFilters}>
            Clear Filters
          </Button>
        </Box>
      </Box>

      {/* Event Creation/Edit Form */}
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
        <DialogTitle>{isEditing ? 'Update Event' : 'Create Event'}</DialogTitle>
        <DialogContent>
          <Box component="form">
            <TextField
              label="Title"
              fullWidth
              margin="dense"
              value={eventData.title}
              onChange={(e) => setEventData({ ...eventData, title: e.target.value })}
              required
            />

            <FormControl fullWidth margin="dense">
              <InputLabel>Event Type</InputLabel>
              <Select
                value={eventData.eventType}
                onChange={handleEventTypeChange}
                required
              >
                <MenuItem value="Case date">Case Date</MenuItem>
                <MenuItem value="Client appointment">Client Appointment</MenuItem>
                <MenuItem value="Court officer appointment">Court Officer Appointment</MenuItem>
              </Select>
            </FormControl>

            {eventData.eventType === 'Case date' && (
              <>
                <TextField
                  label="Court Name"
                  fullWidth
                  margin="dense"
                  value={eventData.courtName}
                  onChange={(e) => setEventData({ ...eventData, courtName: e.target.value })}
                />
                <TextField
                  label="Court Number"
                  fullWidth
                  margin="dense"
                  value={eventData.courtNumber}
                  onChange={(e) => setEventData({ ...eventData, courtNumber: e.target.value })}
                />
              </>
            )}

            {eventData.eventType === 'Client appointment' && (
              <>
                <TextField
                  label="Client Name"
                  fullWidth
                  margin="dense"
                  value={eventData.clientName || ''}
                  onChange={(e) => setEventData({ ...eventData, clientName: e.target.value })}
                />
              </>
            )}

            {eventData.eventType === 'Court officer appointment' && (
              <>
                <TextField
                  label="Judge Name"
                  fullWidth
                  margin="dense"
                  value={eventData.judgeName}
                  onChange={(e) => setEventData({ ...eventData, judgeName: e.target.value })}
                />
              </>
            )}

            <Grid2 container spacing={2}>
              <Grid2 item xs={6} sx={{ minWidth: 120 }}>
                <TextField
                  label="Start Date"
                  fullWidth
                  margin="dense"
                  type="date"
                  value={eventData.startDate}
                  onChange={(e) => setEventData({ ...eventData, startDate: e.target.value })}
                  InputLabelProps={{ shrink: true }}
                  required
                />
              </Grid2>
              <Grid2 item xs={6} sx={{ minWidth: 120 }}>
                <TextField
                  label="Start Time"
                  fullWidth
                  margin="dense"
                  type="time"
                  value={eventData.startTime}
                  onChange={(e) => setEventData({ ...eventData, startTime: e.target.value })}
                  InputLabelProps={{ shrink: true }}
                  required
                />
              </Grid2>

              <Grid2 item xs={6} sx={{ minWidth: 120 }}>
                <TextField
                  label="End Date"
                  fullWidth
                  margin="dense"
                  type="date"
                  value={eventData.endDate}
                  onChange={(e) => setEventData({ ...eventData, endDate: e.target.value })}
                  InputLabelProps={{ shrink: true }}
                  required
                />
              </Grid2>
              
              <Grid2 item xs={6} sx={{ minWidth: 120 }}>
                <TextField
                  label="End Time"
                  fullWidth
                  margin="dense"
                  type="time"
                  value={eventData.endTime}
                  onChange={(e) => setEventData({ ...eventData, endTime: e.target.value })}
                  InputLabelProps={{ shrink: true }}
                  required
                />
              </Grid2>

              <Grid2 item xs={6} sx={{ minWidth: 120 }}>
                <TextField
                  label="Party B Email"
                  fullWidth
                  margin="dense"
                  value={eventData.partyBEmail}
                  onChange={(e) => setEventData({ ...eventData, partyBEmail: e.target.value })}
                />
              </Grid2>
              <Grid2 item xs={6} sx={{ minWidth: 120 }}>
                <FormControl fullWidth margin="dense">
                  <InputLabel>Meeting Type</InputLabel>
                  <Select
                    value={eventData.meetingtype}
                    onChange={(e) => setEventData({ ...eventData, meetingtype: e.target.value })}
                  >
                    <MenuItem value="InPerson">In Person</MenuItem>
                    <MenuItem value="VideoCall">Video Call</MenuItem>
                    <MenuItem value="VoiceCall">Voice Call</MenuItem>
                  </Select>
                </FormControl>
              </Grid2>

              <Grid2 item xs={6} sx={{ minWidth: 120 }}>
                <TextField
                  label="Case ID"
                  fullWidth
                  margin="dense"
                  value={eventData.caseId}
                  onChange={(e) => setEventData({ ...eventData, caseId: e.target.value })}
                />
              </Grid2>
              <Grid2 item xs={6} sx={{ minWidth: 120 }}>
                <FormControl fullWidth margin="dense">
                  <InputLabel>Send Reminder</InputLabel>
                  <Select
                    value={eventData.sendReminder}
                    onChange={(e) => setEventData({ ...eventData, sendReminder: e.target.value })}
                  >
                    <MenuItem value="None">None</MenuItem>
                    <MenuItem value="Email">Email</MenuItem>
                    <MenuItem value="WhatsApp">WhatsApp</MenuItem>
                    <MenuItem value="Both">Both</MenuItem>
                  </Select>
                </FormControl>
              </Grid2>
            </Grid2>
            {errorMessage && (
              <Typography color="error" sx={{ mt: 2 }}>
                {errorMessage}
              </Typography>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleEventSubmit} color="primary">
            {isEditing ? 'Update Event' : 'Submit'}
          </Button>
          {isEditing && (
            <Button 
              onClick={handleDeleteEvent} 
              color="error" 
              style={{ color: 'red' }}
            >
              Delete Event
            </Button>
          )}
        </DialogActions>
      </Dialog>

      {/* FullCalendar Integration */}
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        events={filteredEvents}
        select={handleDateSelect}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay',
        }}
        editable
        selectable
        eventClick={handleEventClick}
      />

      {/* Snackbar for success and error */}
      <Snackbar
        open={successMessage || !!errorMessage}
        autoHideDuration={6000}
        onClose={() => {
          setSuccessMessage(false);
          setErrorMessage('');
        }}
        message={successMessage ? "Event submitted successfully!" : errorMessage}
      />
    </Box>
  );
};

export default Calendar;
