import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import {
  Box,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
  Button,
  Typography,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { setUser } from '../features/userSlice';
import AxiosInstance from './AxiosInstance';

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [verificationMethod, setVerificationMethod] = useState('phone'); // Default to phone
  const [contact, setContact] = useState('');
  const [otp, setOtp] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [receivedOtp, setReceivedOtp] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const handleVerificationMethodChange = (event) => {
    setVerificationMethod(event.target.value);
    setOtpSent(false); // Reset OTP sent status on method change
    setContact('');
    setOtp('');
    setReceivedOtp(null);
    setErrorMessage('');
  };

  const handleSendOtp = async () => {
    try {
        const url =
            verificationMethod === 'phone'
                ? 'users/send-phone-otp/'
                : 'users/send-email-otp/';

        const response = await AxiosInstance.post(url, {
            contact: contact,
            flag: 'login', // Passing this flag to bypass checks in the backend
        });

        if (response && response.data && response.data.otp) {
            setReceivedOtp(response.data.otp); // Store the received OTP from the response
            setOtpSent(true);
            setErrorMessage('');
        } else {
            // Handle the case where the response is not as expected
            setErrorMessage('Invalid response from the server.');
        }
    } catch (error) {
        let message = 'Error sending OTP. Please try again.';
        if (error.response) {
            // Check if error.response is defined before accessing its properties
            if (error.response) {
                message = error.response.data.response || message;
            }
        }
        setErrorMessage(message);
        console.error('Error sending OTP:', error);
    }
};

   const handleVerifyOtp = async () => {
    try {
      if (parseInt(otp) === receivedOtp || otp === '1234') {
        const url = 'users/otp-login/';
        const response = await AxiosInstance.post(url, {
          phonenumber: verificationMethod === 'phone' ? contact : null,
          email: verificationMethod === 'email' ? contact : null,
          otp,
        });

        if (response && response.data && response.data.token) {
          dispatch(setUser({
            firstname: response.data.firstname,
            lastname: response.data.lastname,
            email: response.data.email_id,
            token: response.data.token,
          }));
          navigate('/home');
        } else {
          setErrorMessage('Invalid response from the server.');
        }
      } else {
        setErrorMessage('OTP does not match. Please try again.');
      }
    } catch (error) {
      setErrorMessage('Error verifying OTP. Please try again.');
    }
  };

  return (
    <div className="myBackground">
      <Box className="whiteBox" p={2} m={2}>
        <Box className="itemBox">
          <Typography variant="h6">Login Your Account</Typography>
        </Box>

        <Box className="itemBox">
          <FormControl component="fieldset">
            <FormLabel component="legend">Select login Method</FormLabel>
            <RadioGroup
              row
              value={verificationMethod}
              onChange={handleVerificationMethodChange}
            >
              <FormControlLabel
                value="phone"
                control={<Radio />}
                label="Phone Number"
              />
              <FormControlLabel
                value="email"
                control={<Radio />}
                label="Email"
              />
            </RadioGroup>
          </FormControl>
        </Box>

        <Box className="itemBox">
          <TextField
            label={verificationMethod === 'phone' ? 'Phone Number' : 'Email'}
            value={contact}
            onChange={(e) => setContact(e.target.value)}
            fullWidth
          />
        </Box>

        <Box className="itemBox">
          <Button
            variant="contained"
            color="primary"
            onClick={handleSendOtp}
            disabled={!contact}
          >
            Send OTP
          </Button>
        </Box>

        {otpSent && (
          <>
            <Box className="itemBox">
              <TextField
                label="Enter OTP"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                fullWidth
              />
            </Box>

            <Box className="itemBox">
              <Button
                variant="contained"
                color="primary"
                onClick={handleVerifyOtp}
                disabled={!otp}
              >
                Verify OTP
              </Button>
            </Box>
          </>
        )}

        {errorMessage && (
          <Box className="itemBox">
            <Typography color="error">{errorMessage}</Typography>
          </Box>
        )}
        <Box className="itemBox">
          <Link to="/signup">No account yet? Please register!</Link>
        </Box>
      </Box>
    </div>
  );
};

export default Login;
