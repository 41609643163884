// src/components/SendEmailComponent.js

import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Grid, Input, FormControl } from '@mui/material';
import { AttachFile } from '@mui/icons-material';
import axios from 'axios';

const SendEmailComponent = () => {
//   const [fromEmail, setFromEmail] = useState('');
  const [toEmails, setToEmails] = useState('');
  const [ccEmails, setCcEmails] = useState('');
  const [bccEmails, setBccEmails] = useState('');
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [attachments, setAttachments] = useState([]);

  const handleFileChange = (e) => {
    setAttachments([...e.target.files]);
  };

  const handleSendEmail = async () => {
    const formData = new FormData();
    // formData.append('from_email', fromEmail);
    formData.append('to_emails', toEmails.split(','));
    formData.append('cc_emails', ccEmails.split(','));
    formData.append('bcc_emails', bccEmails.split(','));
    formData.append('subject', subject);
    formData.append('body', body);
    attachments.forEach((file, index) => {
      formData.append(`attachments`, file);
    });

    try {
      const response = await axios.post('http://127.0.0.1:8000/utils/send-email/', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      alert('Email sent successfully:', {response});
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Failed to send email');
    }
  };

  return (
    <Box sx={{ padding: 2, maxWidth: 800, margin: 'auto' }}>
      <Typography variant="h5" gutterBottom>Send Email</Typography>
      <Grid container spacing={2}>
        {/* <Grid item xs={12}>
          <TextField
            fullWidth
            label="From"
            variant="outlined"
            value={fromEmail}
            onChange={(e) => setFromEmail(e.target.value)}
          />
        </Grid> */}
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="To"
            variant="outlined"
            value={toEmails}
            onChange={(e) => setToEmails(e.target.value)}
            helperText="Separate multiple emails with commas"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="CC"
            variant="outlined"
            value={ccEmails}
            onChange={(e) => setCcEmails(e.target.value)}
            helperText="Separate multiple emails with commas"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="BCC"
            variant="outlined"
            value={bccEmails}
            onChange={(e) => setBccEmails(e.target.value)}
            helperText="Separate multiple emails with commas"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Subject"
            variant="outlined"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Body"
            variant="outlined"
            multiline
            rows={4}
            value={body}
            onChange={(e) => setBody(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <Input
              type="file"
              inputProps={{ multiple: true }}
              onChange={handleFileChange}
              style={{ display: 'none' }}
              id="file-input"
            />
            <label htmlFor="file-input">
              <Button
                variant="contained"
                color="primary"
                component="span"
                startIcon={<AttachFile />}
              >
                Add Attachments
              </Button>
            </label>
            {attachments.length > 0 && (
              <Box>
                <Typography variant="subtitle1">Attachments:</Typography>
                {attachments.map((file, index) => (
                  <Typography key={index} variant="body2">
                    {file.name}
                  </Typography>
                ))}
              </Box>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSendEmail}
          >
            Send Email
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SendEmailComponent;
