import axios from 'axios'
import { store } from '../store';

// const baseUrl = 'http://164.52.205.79:8000/'
const baseUrl = 'http://adalatai.com/api/'

const AxiosInstance = axios.create({
    baseURL: baseUrl,
    timeout: 60000, 
    headers:{
        "Content-Type":"application/json",
         accept: "application/json"
    }
})

// Add a request interceptor to include JWT token in headers
AxiosInstance.interceptors.request.use(
  (config) => {
    const state = store.getState();
    const token = state.user.token; // Get token from Redux store
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default AxiosInstance;
