
const About = () =>{
    return(
        <div> 
            This is the about page
        </div>
    )

}

export default About